import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as System from "../../design-system"
import Image from "../components/image"
import StaticImage from "../components/static-image"
import { CURRENT_NUMBER_OF_THE_PRODUCTS } from "../shared/classConsts/hello-framer-x"

import { commaPipe } from "ruucm-util"

var PurchaseArea, PurchaseSection
var useStore, classOption, setClassOption
const Page = ({ installment, ...props }) => {
  if (typeof window !== "undefined") {
    PurchaseArea = require("../components/PurchaseArea").default
    PurchaseSection = require("../components/PurchaseArea/PurchaseSection")
      .default
    useStore = require("../datas/useStore").default
  }
  if (useStore) [classOption, setClassOption] = useStore()

  return (
    <Layout>
      <SEO
        title="하버스쿨 클래스 분할 결제창"
        description=""
        metaImage={require("../images/hf3-1-min.png")}
      />
      {typeof window !== "undefined" && (
        <System.PageTitle
          h1={
            "클래스 분할 결제창 - " +
            commaPipe(Math.round(classOption.total / installment)) +
            "원 x " +
            installment +
            "개월"
          }
        />
      )}

      {typeof window !== "undefined" && (
        <div
          style={{
            width: "100vw",
            position: "absolute",
            left: 0,
            zIndex: 1,
            background: "white",
            height: 600,
          }}
        >
          <PurchaseSection manual={true} installment={installment} />
        </div>
      )}
    </Layout>
  )
}

Page.defaultProps = {
  installment: 3,
}

export default Page
